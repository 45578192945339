import { withAuthenticationRequired } from '@auth0/auth0-react'
import PageLoadIndicator from 'Components/PageLoadIndicator/PageLoadIndicator'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Route, Routes, useMatch } from 'react-router-dom'
import { LayoutWrapper } from 'Util/LayoutWrapper'
import { AdminPage } from './Pages/AdminPage'

const ErrorFallback = ({ error /*, resetErrorBoundary */ }: FallbackProps) => {
  return (
    <div role="alert">
      <span>Something went wrong:</span>
      <pre>{error?.message}</pre>
      {/*  <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  )
}

const AdminRoot = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const pathname = useMatch('end')?.pathname || ''

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Routes>
        <Route path={pathname} element={<LayoutWrapper element={<AdminPage />} />} />
      </Routes>
    </ErrorBoundary>
  )
}

export default withAuthenticationRequired(AdminRoot, {
  onRedirecting: () => <PageLoadIndicator />
})
