import { FC, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SharePointSites from 'Features/SharePoint'
import AdminRoot from 'Features/Admin'
import Loader from 'suspenseSetup'
import { useAuth0 } from '@auth0/auth0-react'
import { ThemeProvider } from '@mui/material'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { lightTheme } from 'theme'

const App: FC = () => {
  const { error } = useAuth0()

  if (error) {
    return <div>{error.message}</div>
  }

  return (
    <ThemeProvider theme={lightTheme}>
      <SCThemeProvider theme={lightTheme}>
        <Suspense fallback={<Loader />}>
          <BrowserRouter>
            <Routes>
              <Route element={<SharePointSites />} path="*" />
              <Route element={<AdminRoot />} path="/admin" />
            </Routes>
          </BrowserRouter>
        </Suspense>
      </SCThemeProvider>
    </ThemeProvider>
  )
}
export { App }
export default App
