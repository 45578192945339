import { FC } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

type SharePointLayoutProps = {
  heading: string
  text: string
}

const SharePointLayout: FC<SharePointLayoutProps> = props => {
  const { heading, text, children } = props
  const { user } = useAuth0()

  return (
    <>
      <h2>{heading}</h2>
      <div>
        Hei {user?.name}! {text}
      </div>
      {children}
    </>
  )
}

export { SharePointLayout }
export default SharePointLayout
