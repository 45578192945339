import { useAuth0 } from '@auth0/auth0-react'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import {
  clearDownloadLink,
  getFileDownloadLink,
  SharePointResponse
} from 'Features/SharePoint/sharepointSlice'
import { useEffect } from 'react'
import useTypedSelector, { useAppDispatch } from 'Store'
import styled from 'styled-components'

const Centered = 'display: flex; align-items: center;'
const FileDownloadButtonStyled = styled.button`
  ${Centered}
  justify-content: space-between;
  padding: 15px 16px;
  text-align: left;
  background-color: inherit;
  border: none;
  border-radius: 4px;
  max-width: calc(100vw - 40px);
  gap: 5px;
  :hover {
    background-color: #d9d9d9;
  }
`
const DownloadIconContainer = styled('span')`
  ${Centered}
  color: ${props => props.theme.palette.primary.main};
  gap: 5px;
`

type FileDownloadButtonProps = {
  item: SharePointResponse
  siteId: string
  listId: string
}

const FileDownloadButton = (props: FileDownloadButtonProps) => {
  const dispatch = useAppDispatch()
  const { downloadLink, loading } = useTypedSelector(state => state.sharepoint)
  const { getAccessTokenSilently } = useAuth0()
  const { item, siteId, listId } = props
  const downloadURL = downloadLink?.url
  const isDownloading = !!downloadLink?.downloading
  const isForThisItem = downloadLink?.itemId === item.id

  useEffect(() => {
    if (isForThisItem && !isDownloading && downloadURL) {
      const anchor = window.document.createElement('a')

      anchor.href = downloadURL
      anchor.download = item.name

      anchor.click()
      dispatch(clearDownloadLink())
    }
  }, [downloadURL])

  const handleFileDownload = () => {
    getAccessTokenSilently().then(accessToken => {
      dispatch(
        getFileDownloadLink({
          accessToken: accessToken,
          siteId: siteId,
          listId: listId,
          itemId: item.id
        })
      )
    })
  }

  // Only allow download if there are no files already downloading or other
  // pending requests
  const disableButton = isDownloading || loading === 'pending'

  return (
    <FileDownloadButtonStyled onClick={handleFileDownload} disabled={disableButton}>
      <span title={item.name} style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {item.name}
      </span>
      <DownloadIconContainer>
        {isForThisItem && isDownloading ? (
          <>
            Ladataan...
            <CircularProgress size={25} />
          </>
        ) : (
          <>
            Lataa
            <CloudDownloadIcon />
          </>
        )}
      </DownloadIconContainer>
    </FileDownloadButtonStyled>
  )
}

export default FileDownloadButton
