import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchSharePointLists } from '../sharepointSlice'
import useTypedSelector, { useAppDispatch } from 'Store'
import { useParams } from 'react-router-dom'
import SharePointLink from 'Components/SharePointLink'
import SharePointLinkContainer from 'Components/SharePointLink/SharePointLinkContainer'
import PageLoadIndicator from 'Components/PageLoadIndicator/PageLoadIndicator'
import ErrorAlert from 'Components/ErrorAlert'

const SharePointLists = () => {
  const dispatch = useAppDispatch()
  const { lists, pageLoading, error } = useTypedSelector(state => state.sharepoint)
  const { getAccessTokenSilently } = useAuth0()
  const { site_id } = useParams()

  useEffect(() => {
    if (site_id) {
      getAccessTokenSilently().then(accessToken =>
        dispatch(fetchSharePointLists({ accessToken: accessToken, siteId: site_id }))
      )
    }
  }, [])

  if (!site_id) {
    return null
  } else if (error) {
    return <ErrorAlert text={`Virhe ladatessa listoja: ${error}`} />
  }

  return (
    <>
      {pageLoading ? (
        <PageLoadIndicator />
      ) : (
        <SharePointLinkContainer>
          {lists.map(list => (
            <SharePointLink key={list.id} navigateTo={`/${site_id}/lists/${list.id}/items`}>
              {list.name}
            </SharePointLink>
          ))}
        </SharePointLinkContainer>
      )}
    </>
  )
}

export { SharePointLists }
