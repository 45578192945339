import { BasicLayout } from 'Components/Layout/Templates'
import { FC, ReactNode } from 'react'

type LayoutWrapperProps = {
  layout?: FC
  element: ReactNode
}

const LayoutWrapper = ({ layout: Layout = BasicLayout, element }: LayoutWrapperProps) => (
  <Layout>{element}</Layout>
)

export { LayoutWrapper }
export default LayoutWrapper
