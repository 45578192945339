import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

const axiosOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Accept-Language': 'fi-FI,fi'
  }
}

const getAxiosInstance = (): AxiosInstance => {
  if (!axiosOptions.headers) {
    axiosOptions.headers = {}
  }

  const instance = axios.create(axiosOptions)

  return instance
}

export { getAxiosInstance }
