import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchSharePointItems } from '../sharepointSlice'
import useTypedSelector, { useAppDispatch } from 'Store'
import { useParams } from 'react-router-dom'
import SharePointLinkContainer from 'Components/SharePointLink/SharePointLinkContainer'
import FileDownloadButton from 'Components/FileDownloadButton'
import PageLoadIndicator from 'Components/PageLoadIndicator/PageLoadIndicator'
import ErrorAlert from 'Components/ErrorAlert'

const SharePointItems = () => {
  const dispatch = useAppDispatch()
  const { items, pageLoading, error } = useTypedSelector(state => state.sharepoint)
  const { getAccessTokenSilently } = useAuth0()
  const { site_id, list_id } = useParams()

  useEffect(() => {
    if (site_id && list_id) {
      getAccessTokenSilently().then(accessToken =>
        dispatch(
          fetchSharePointItems({ accessToken: accessToken, siteId: site_id, listId: list_id })
        )
      )
    }
  }, [])

  if (!site_id || !list_id) {
    return null
  } else if (error) {
    return <ErrorAlert text={`Virhe ladatessa tiedostoja: ${error}`} />
  }

  return (
    <>
      {pageLoading ? (
        <PageLoadIndicator />
      ) : (
        <SharePointLinkContainer>
          {items.map(item => (
            <FileDownloadButton key={item.id} item={item} siteId={site_id} listId={list_id} />
          ))}
        </SharePointLinkContainer>
      )}
    </>
  )
}

export { SharePointItems }
