import Box from '@mui/material/Box'
import { Import } from 'Components/Admin/Import'
import { FC } from 'react'

const AdminPage: FC = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Import importType="users" />
      <Import importType="roles" />
    </Box>
  )
}

export { AdminPage }
