import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { store } from 'Store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { App } from './App'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import history from './Util/history'

const render = () => {
  const onRedirectCallback = (appState: AppState | undefined) => {
    history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
  }

  const providerConfig = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }
  }

  ReactDOM.render(
    <StrictMode>
      <Provider store={store}>
        <Auth0Provider {...providerConfig} useRefreshTokens={true} cacheLocation="localstorage">
          <App />
        </Auth0Provider>
      </Provider>
    </StrictMode>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
