import Alert from '@mui/material/Alert/Alert'

type ErrorAlertProps = {
  text: string
}

const ErrorAlert = ({ text }: ErrorAlertProps) => {
  return (
    <Alert severity="error" sx={{ margin: 2 }}>
      {text}
    </Alert>
  )
}

export default ErrorAlert
