import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAxiosInstance } from 'Util/ApiClient'
import actionGenerator from 'Util/actionGenerator'

export type SharePointResponse = {
  id: string
  name: string
}

type DownloadLinkState = {
  itemId: string
  url?: string
  downloading: boolean
}

type ErrorResponse = {
  message: string
}

const fetchSharePointSites = createAsyncThunk<unknown, string>(
  'sharepoint/fetchSharePointSites',
  async (accessToken: string) => {
    const response = await getAxiosInstance().get<unknown>(
      `${process.env.REACT_APP_API_BASE_URL}/getUserSharePointSites`,
      {
        headers: { Authorization: `Bearer ${accessToken}` }
      }
    )

    return response.data
  }
)

const fetchSharePointLists = createAsyncThunk<unknown, { accessToken: string; siteId: string }>(
  'sharepoint/fetchSharePointLists',
  async payload => {
    const response = await getAxiosInstance().get<unknown>(
      `${process.env.REACT_APP_API_BASE_URL}/getUserSharePointSiteLists`,
      {
        headers: { Authorization: `Bearer ${payload.accessToken}` },
        params: { site_id: payload.siteId }
      }
    )

    return response.data
  }
)

const fetchSharePointItems = createAsyncThunk<
  unknown,
  { accessToken: string; siteId: string; listId: string }
>('sharepoint/getUserSharePointItems', async payload => {
  const response = await getAxiosInstance().get<unknown>(
    `${process.env.REACT_APP_API_BASE_URL}/getUserSharePointItems`,
    {
      headers: { Authorization: `Bearer ${payload.accessToken}` },
      params: { site_id: payload.siteId, list_id: payload.listId }
    }
  )

  return response.data
})

const getFileDownloadLink = createAsyncThunk<
  string,
  { accessToken: string; siteId: string; listId: string; itemId: string }
>('sharepoint/getFileDownloadLink', async payload => {
  const response = await getAxiosInstance().get<string>(
    `${process.env.REACT_APP_API_BASE_URL}/getFileDownloadLink`,
    {
      headers: { Authorization: `Bearer ${payload.accessToken}` },
      params: { site_id: payload.siteId, list_id: payload.listId, item_id: payload.itemId }
    }
  )

  return response.data
})

type SharePointSitesState = {
  sites: SharePointResponse[]
  lists: SharePointResponse[]
  items: SharePointResponse[]
  downloadLink?: DownloadLinkState
  loading: 'idle' | 'pending' | 'fulfilled' | 'rejected'
  pageLoading: boolean
  error?: string | null
}

const initialState: SharePointSitesState = {
  sites: [],
  lists: [],
  items: [],
  loading: 'idle',
  pageLoading: true
}

const genericActions = actionGenerator('sharepoint')

const setSharePointObjects = (
  state: SharePointSitesState,
  payload: unknown,
  entityType: 'sites' | 'lists' | 'items'
) => {
  if (typeof payload === 'object') {
    state[entityType] = payload as SharePointResponse[]
    state.error = null
  } else {
    state.error = typeof payload === 'string' ? payload : 'Unknown error'
  }
}

const sharepointSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    clearDownloadLink(state) {
      delete state.downloadLink
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSharePointSites.pending, state => {
        state.pageLoading = true
      })
      .addCase(fetchSharePointSites.fulfilled, (state, { payload }) => {
        setSharePointObjects(state, payload, 'sites')
      })
      .addCase(fetchSharePointLists.pending, state => {
        state.pageLoading = true
      })
      .addCase(fetchSharePointLists.fulfilled, (state, { payload }) => {
        setSharePointObjects(state, payload, 'lists')
      })
      .addCase(fetchSharePointItems.pending, state => {
        state.pageLoading = true
      })
      .addCase(fetchSharePointItems.fulfilled, (state, { payload }) => {
        setSharePointObjects(state, payload, 'items')
      })
      .addCase(getFileDownloadLink.pending, (state, { meta }) => {
        state.downloadLink = { itemId: meta.arg.itemId, downloading: true }
      })
      .addCase(getFileDownloadLink.fulfilled, (state, { payload, meta }) => {
        if (state.downloadLink) {
          state.downloadLink.url = payload
          state.downloadLink.downloading = false
        } else {
          state.downloadLink = { itemId: meta.arg.itemId, url: payload, downloading: false }
        }

        state.error = null
      })
      .addCase(genericActions.ResetAction, () => initialState)
      .addMatcher(genericActions.isPendingAction, state => {
        state.loading = 'pending'
        state.error = null
      })
      .addMatcher(genericActions.isRejectedAction, (state, { error }) => {
        state.loading = 'rejected'
        state.error = typeof error === 'object' ? (error as ErrorResponse).message : 'Unknown error'
      })
      .addMatcher(genericActions.isFulfilledAction, state => {
        state.loading = 'fulfilled'
        state.pageLoading = false
      })
  }
})

export default sharepointSlice.reducer

const {
  actions: { clearDownloadLink }
} = sharepointSlice

export {
  fetchSharePointSites,
  fetchSharePointLists,
  fetchSharePointItems,
  getFileDownloadLink,
  clearDownloadLink
}
