import CircularProgress from '@mui/material/CircularProgress/CircularProgress'

const PageLoadIndicator = () => {
  return (
    <div style={{ marginTop: '25px' }}>
      <CircularProgress size={50} sx={{}} />
    </div>
  )
}

export default PageLoadIndicator
