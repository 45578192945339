import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'Store'
import { getAxiosInstance } from 'Util/ApiClient'
import actionGenerator from 'Util/actionGenerator'

type ImportJob = {
  status: 'pending' | 'completed' | 'failed'
}

type UserImport = ImportJob & {
  id?: string
}

type AdminState = {
  userImportJob?: UserImport
  userRolesImportJob?: ImportJob
}

const upsertUsers = createAsyncThunk<string, { file: Blob; accessToken: string }>(
  'admin/upsertUsers',
  async payload => {
    const { file, accessToken } = payload
    const formData = new FormData()

    formData.append('users', file)

    const response = await getAxiosInstance().post<string>(
      `${process.env.REACT_APP_API_BASE_URL}/upsertUsers`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' } }
    )

    return response.data
  }
)

const getUserImportStatus = createAsyncThunk<'pending' | 'completed' | 'failed', string>(
  'admin/getUserImportStatus',
  async (accessToken, { getState }) => {
    const {
      admin: { userImportJob }
    } = getState() as RootState

    const response = await getAxiosInstance().get<'pending' | 'completed' | 'failed'>(
      `${process.env.REACT_APP_API_BASE_URL}/getUserImportJobStatus`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
        params: { job_id: userImportJob?.id }
      }
    )

    return response.data
  }
)

const addRolesToUsers = createAsyncThunk<string, { file: Blob; accessToken: string }>(
  'admin/addRolesToUsers',
  async payload => {
    const { file, accessToken } = payload
    const formData = new FormData()

    formData.append('file', file)

    const response = await getAxiosInstance().post<string>(
      `${process.env.REACT_APP_API_BASE_URL}/updateUserRoles`,
      formData,
      { headers: { Authorization: `Bearer ${accessToken}`, 'Content-Type': 'multipart/form-data' } }
    )

    return response.data
  }
)

const initialState: AdminState = {}

const genericActions = actionGenerator('admin')

/**
 * Helper function to set the status of an import job
 * @param state State of the admin slice
 * @param newStatus The new status of the import job
 * @param entityType State property to update
 */
const setStatus = (
  state: AdminState,
  newStatus: 'pending' | 'completed' | 'failed',
  entityType: 'userImportJob' | 'userRolesImportJob'
) => {
  if (state[entityType]) {
    ;(state[entityType] as UserImport | ImportJob).status = newStatus
  } else {
    state[entityType] = { status: newStatus }
  }
}

const adminSlice = createSlice({
  name: genericActions.name,
  initialState,
  reducers: {
    setUserUpsertJobStatus(state, action: PayloadAction<'pending' | 'completed' | 'failed'>) {
      setStatus(state, action.payload, 'userImportJob')
    },
    setUserRolesImportStatus(state, action: PayloadAction<'pending' | 'completed' | 'failed'>) {
      setStatus(state, action.payload, 'userRolesImportJob')
    }
  },
  extraReducers: builder => {
    builder
      .addCase(upsertUsers.fulfilled, (state, { payload }) => {
        state.userImportJob = { id: payload, status: 'pending' }
      })
      .addCase(upsertUsers.rejected, state => {
        setStatus(state, 'failed', 'userImportJob')
      })
      .addCase(getUserImportStatus.fulfilled, (state, { payload }) => {
        if (state.userImportJob) {
          state.userImportJob.status = payload
        }
      })
      .addCase(addRolesToUsers.fulfilled, state => {
        state.userRolesImportJob = { status: 'completed' }
      })
      .addCase(addRolesToUsers.rejected, state => {
        setStatus(state, 'failed', 'userRolesImportJob')
      })
      .addCase(genericActions.ResetAction, () => initialState)
  }
})

const {
  actions: { setUserUpsertJobStatus, setUserRolesImportStatus }
} = adminSlice

export default adminSlice.reducer
export {
  upsertUsers,
  getUserImportStatus,
  setUserUpsertJobStatus,
  setUserRolesImportStatus,
  addRolesToUsers
}
