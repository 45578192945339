import { FC } from 'react'

import { HeaderBar } from 'Components/Layout'
import { styled } from 'styled-components'

const Grid = styled.div`
  display: grid;
  gap: 20px;
  grid-template-areas:
    'header'
    'main';
  margin: 20px;
`

const BasicLayout: FC = ({ children }) => {
  return (
    <Grid>
      <HeaderBar />

      <div style={{ gridArea: 'main' }}>{children}</div>
    </Grid>
  )
}

export { BasicLayout }
export default BasicLayout
