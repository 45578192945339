import { withAuthenticationRequired } from '@auth0/auth0-react'
import SharePointLayout from 'Components/Layout/Templates/SharePointLayout'
import PageLoadIndicator from 'Components/PageLoadIndicator/PageLoadIndicator'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Route, Routes, useMatch } from 'react-router-dom'

import { LayoutWrapper } from 'Util/LayoutWrapper'

import { SharePointSites } from './Pages'
import { SharePointItems } from './Pages/SharePointItems'
import { SharePointLists } from './Pages/SharePointLists'

const ErrorFallback = ({ error /*, resetErrorBoundary */ }: FallbackProps) => {
  return (
    <div role="alert">
      <span>Something went wrong:</span>
      <pre>{error?.message}</pre>
      {/*  <button onClick={resetErrorBoundary}>Try again</button> */}
    </div>
  )
}

const SharePointRoot = () => {
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const pathname = useMatch('end')?.pathname || ''

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <Routes>
        <Route
          path={pathname}
          element={
            <LayoutWrapper
              element={
                <SharePointLayout
                  heading="Jäsenmateriaalit"
                  text="Valitsemalla jäsenmateriaalit ja sen alta teema-alueet, saat käyttöösi vain jäsenille tarkoitetut aineistot."
                >
                  <SharePointSites />
                </SharePointLayout>
              }
            />
          }
        />
        <Route
          path={`${pathname}/:site_id/lists/`}
          element={
            <LayoutWrapper
              element={
                <SharePointLayout
                  heading="Teema-alueet"
                  text="Alla on listattuna käyttöoikeuksiesi mukaiset teema-alueet."
                >
                  <SharePointLists />
                </SharePointLayout>
              }
            />
          }
        />
        <Route
          path={`${pathname}/:site_id/lists/:list_id/items`}
          element={
            <LayoutWrapper
              element={
                <SharePointLayout
                  heading="Materiaalit"
                  text="Alla on listattuna käyttöoikeuksiesi mukaiset materiaalit."
                >
                  <SharePointItems />
                </SharePointLayout>
              }
            />
          }
        />
      </Routes>
    </ErrorBoundary>
  )
}

export default withAuthenticationRequired(SharePointRoot, {
  onRedirecting: () => <PageLoadIndicator />
})
