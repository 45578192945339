import { FC } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SharePointLinkStyled = styled(Link)`
  padding: 15px 16px;
  text-align: left;
  border-radius: 4px;
  text-decoration: none;
  color: black;
  :visited {
    color: inherit;
  }
  :hover {
    background-color: #d9d9d9;
  }
`

type SharePointLinkProps = {
  navigateTo: string
}

const SharePointLink: FC<SharePointLinkProps> = props => {
  const { navigateTo, children } = props

  return <SharePointLinkStyled to={navigateTo}>{children}</SharePointLinkStyled>
}

export default SharePointLink
