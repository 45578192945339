import IconButton from '@mui/material/IconButton/IconButton'

type IconButtonWithTextProps = {
  onClick: () => void
  text: string
  icon: JSX.Element
}

const IconButtonWithText = (props: IconButtonWithTextProps) => {
  const { onClick, text, icon } = props

  return (
    <IconButton color="primary" onClick={onClick} sx={{ gap: 1 }}>
      <span style={{ fontSize: 14 }}>{text}</span>
      {icon}
    </IconButton>
  )
}

export default IconButtonWithText
