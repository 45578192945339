import jwt_decode from 'jwt-decode'

export const isUserAdmin = (accessToken: string): boolean => {
  const decodedToken = jwt_decode(accessToken)

  return !!(
    typeof decodedToken === 'object' &&
    (decodedToken as { permissions?: string[] }).permissions?.includes('update:admin:users')
  )
}
