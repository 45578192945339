import { createTheme } from '@mui/material/styles'
import { Theme } from '@mui/material'

export const lightTheme: Theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#9D66FE'
    },
    background: {
      default: '#f5f5f5'
    }
  }
})
