import { FC, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchSharePointSites } from '../sharepointSlice'
import useTypedSelector, { useAppDispatch } from 'Store'
import SharePointLink from 'Components/SharePointLink'
import SharePointLinkContainer from 'Components/SharePointLink/SharePointLinkContainer'
import PageLoadIndicator from 'Components/PageLoadIndicator/PageLoadIndicator'
import ErrorAlert from 'Components/ErrorAlert'

const SharePointSites: FC = () => {
  const dispatch = useAppDispatch()
  const { sites, pageLoading, error } = useTypedSelector(state => state.sharepoint)
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    getAccessTokenSilently().then(accessToken => dispatch(fetchSharePointSites(accessToken)))
  }, [])

  if (error) {
    return <ErrorAlert text={`Virhe ladatessa sivuja: ${error}`} />
  }

  return (
    <>
      {pageLoading ? (
        <PageLoadIndicator />
      ) : (
        <SharePointLinkContainer>
          {sites.map(site => (
            <SharePointLink key={site.id} navigateTo={`/${site.id}/lists`}>
              {site.name}
            </SharePointLink>
          ))}
        </SharePointLinkContainer>
      )}
    </>
  )
}

export { SharePointSites }
