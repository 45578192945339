import { useEffect, useState } from 'react'
import Papa from 'papaparse'
import Button from '@mui/material/Button'
import { useAuth0 } from '@auth0/auth0-react'
import { isUserAdmin } from 'Util/common'
import useTypedSelector, { useAppDispatch } from 'Store'
import { Box, CircularProgress } from '@mui/material'
import styled from 'styled-components'
import {
  addRolesToUsers,
  getUserImportStatus,
  setUserRolesImportStatus,
  setUserUpsertJobStatus,
  upsertUsers
} from 'Features/Admin/adminSlice'

export type UserDetailsCSVRow = {
  Nimi: string
  Sähköposti: string
  Päätaso: string
}

type ImportProps = {
  importType: 'users' | 'roles'
}

type UserRolesImportObject = {
  email: string
  roles: string[]
}

const CompletedStatus = styled('div')`
  color: ${props => props.theme.palette.success.main};
`

const FailureStatus = styled('div')`
  color: ${props => props.theme.palette.error.main};
`

const Import = (props: ImportProps) => {
  const dispatch = useAppDispatch()
  const { userImportJob, userRolesImportJob } = useTypedSelector(state => state.admin)
  const [isAdmin, setIsAdmin] = useState(false)
  const [csvFileName, setCSVFileName] = useState<string>('')
  const [importData, setImportData] = useState<Blob | null>()
  const [importJobStatusPoll, setImportJobStatusPoll] = useState<ReturnType<typeof setInterval>>()
  const { getAccessTokenSilently } = useAuth0()
  const { importType } = props
  const importJob = importType === 'users' ? userImportJob : userRolesImportJob

  useEffect(() => {
    getAccessTokenSilently().then(accessToken => setIsAdmin(isUserAdmin(accessToken)))
  }, [])

  useEffect(() => {
    if (importType === 'users') {
      if (importJob && importJob.status !== 'completed' && !importJobStatusPoll) {
        getAccessTokenSilently().then(accessToken =>
          setImportJobStatusPoll(
            setInterval(() => dispatch(getUserImportStatus(accessToken)), 5000)
          )
        )
      } else {
        clearInterval(importJobStatusPoll)
        setImportJobStatusPoll(undefined)
      }

      return () => clearInterval(importJobStatusPoll)
    }
  }, [importJob?.status])

  if (!isAdmin) {
    return null
  }

  const handleCSVFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (!file) {
      return
    }

    setCSVFileName(file.name)

    Papa.parse(file, {
      header: true,
      complete: results => {
        const filteredRows = (results.data as UserDetailsCSVRow[]).filter(user => user.Sähköposti)

        setImportData(
          new Blob([
            JSON.stringify(
              importType === 'users'
                ? filteredRows.map(user => ({ email: user.Sähköposti, name: user.Nimi }))
                : Object.values(
                    filteredRows
                      .map(user => ({ email: user.Sähköposti, role: user.Päätaso }))
                      .reduce((acc: Record<string, UserRolesImportObject>, { email, role }) => {
                        if (role) {
                          if (acc[email]) {
                            acc[email].roles.push(role)
                          } else {
                            acc[email] = { email, roles: [role] }
                          }
                        }

                        return acc
                      }, {})
                  )
            )
          ])
        )
      }
    })
  }

  const startImport = () => {
    if (importData) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let setStatusAction: any = setUserRolesImportStatus
      let importAction = addRolesToUsers

      if (importType === 'users') {
        setStatusAction = setUserUpsertJobStatus
        importAction = upsertUsers
      }

      dispatch(setStatusAction('pending'))
      getAccessTokenSilently().then(accessToken => {
        dispatch(importAction({ file: importData, accessToken: accessToken }))
        setImportData(null)
      })
    }
  }

  const importMsgPrefix = importType === 'users' ? 'Käyttäjätietojen' : 'Roolien'
  let upsertComponent = null

  if (importData) {
    upsertComponent = (
      <div>
        <Button variant="contained" onClick={startImport}>
          Aloita {importMsgPrefix} päivitys
        </Button>
      </div>
    )
  } else if (importJob) {
    if (importJob?.status === 'completed') {
      upsertComponent = <CompletedStatus>{importMsgPrefix} päivitys valmis!</CompletedStatus>
    } else if (importJob?.status === 'failed') {
      upsertComponent = <FailureStatus>{importMsgPrefix} päivitys epäonnistui!</FailureStatus>
    } else {
      upsertComponent = (
        <Box display="flex" alignItems="center" gap={2}>
          <CircularProgress disableShrink={true} />
          Päivitetään {importType === 'users' ? 'käyttäjätietoja' : 'rooleja'} ...
        </Box>
      )
    }
  }

  return (
    <Box display="flex" gap={2} flexDirection="column" textAlign="left">
      <div>
        <label htmlFor={`csv-file-upload-${importType}`} style={{ marginRight: 10 }}>
          <Button component="span" variant="contained">
            Lataa {importType === 'users' ? 'käyttäjätiedot' : 'roolit'}
          </Button>
        </label>
        <span>{csvFileName}</span>
        <input
          id={`csv-file-upload-${importType}`}
          type="file"
          accept=".csv"
          onChange={handleCSVFileUpload}
          style={{ display: 'none' }}
        />
      </div>
      {upsertComponent}
    </Box>
  )
}

export { Import }
