import { useAuth0 } from '@auth0/auth0-react'
import Logo from 'assets/images/rakli-logo-purple.svg'
import LogoutIcon from '@mui/icons-material/Logout'
import SettingsIcon from '@mui/icons-material/Settings'
import IconButtonWithText from 'Components/IconButtonWithText'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { isUserAdmin } from 'Util/common'

const HeaderBar = () => {
  const [isAdmin, setIsAdmin] = useState(false)
  const navigate = useNavigate()
  const { logout, getAccessTokenSilently } = useAuth0()
  const logoutWithRedirect = () =>
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI
      }
    })

  useEffect(() => {
    getAccessTokenSilently().then(accessToken => setIsAdmin(isUserAdmin(accessToken)))
  }, [])

  return (
    <div style={{ gridArea: 'header' }}>
      <header
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '10px' }}
      >
        <img src={Logo} alt="Rakli logo" />
        <div>
          {isAdmin && (
            <IconButtonWithText
              onClick={() => navigate('/admin')}
              text="Admin"
              icon={<SettingsIcon />}
            />
          )}
          <IconButtonWithText
            onClick={logoutWithRedirect}
            text="Kirjaudu ulos"
            icon={<LogoutIcon />}
          />
        </div>
      </header>
    </div>
  )
}

export default HeaderBar
